// extracted by mini-css-extract-plugin
export var main = "faq-module--main--kxrcI";
export var article = "faq-module--article--WFUJs";
export var headingLogo = "faq-module--headingLogo--ReE0p";
export var twoLineHeader = "faq-module--twoLineHeader--uq1od";
export var bendableGold = "faq-module--bendableGold--88yKU";
export var faqItem = "faq-module--faqItem--QULLa";
export var faqHeading = "faq-module--faqHeading--OtmZ8";
export var faqBody = "faq-module--faqBody--Y4f6g";
export var latestnewsarticleheadline = "faq-module--latestnewsarticleheadline--lSmQ7";
export var dateline = "faq-module--dateline--gNi40";
export var subhead = "faq-module--subhead--4OVDM";
export var carouselSlide = "faq-module--carouselSlide--MV-qK";