import * as React from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import { StaticImage } from 'gatsby-plugin-image';
import { isMobile } from 'react-device-detect';

import BendableLogo from '../svg/Bendable_Logo.svg';
import BendableLabsLogo from '../components/footer/BendableLabsLogo';

import {
  main,
  article,
  bendableGold,
  twoLineHeader,
  headingLogo,
  faqItem,
  faqHeading,
  faqBody
} from './faq.module.scss';


const Faq = () => {

  return (
    <Layout pageTitle="Bendable Maine">

      <div id="page" className="Site" >
        <a className="skip-link screen-reader-text" href="#primary">Skip to content</a>

        <header id="masthead" className="Site-header">
          <div className="Site-branding">
            <a href="/" className="custom-logo-link" rel="home"><img width="236" height="74" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/bendable-logo.svg" className="custom-logo" alt="Bendable" /></a>		
          </div>
          
          <div className="Community-header">
            <div className="Community-name">Maine</div>  			
            <div className="back">
              <Link to="/maine" rel="home">
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M15 7.3132C15 7.56181 14.9012 7.80024 14.7254 7.97603C14.5496 8.15183 14.3112 8.25059 14.0626 8.25059H3.20194L7.22712 12.2739C7.31427 12.361 7.38341 12.4645 7.43058 12.5784C7.47775 12.6923 7.50202 12.8143 7.50202 12.9376C7.50202 13.0608 7.47775 13.1829 7.43058 13.2967C7.38341 13.4106 7.31427 13.5141 7.22712 13.6012C7.13996 13.6884 7.0365 13.7575 6.92262 13.8047C6.80875 13.8519 6.6867 13.8761 6.56344 13.8761C6.44019 13.8761 6.31814 13.8519 6.20426 13.8047C6.09039 13.7575 5.98692 13.6884 5.89977 13.6012L0.275395 7.97687C0.188099 7.8898 0.118838 7.78635 0.0715815 7.67247C0.0243247 7.55858 0 7.4365 0 7.3132C0 7.1899 0.0243247 7.06781 0.0715815 6.95392C0.118838 6.84004 0.188099 6.7366 0.275395 6.64952L5.89977 1.02515C6.07578 0.84913 6.31452 0.750244 6.56344 0.750244C6.81237 0.750244 7.0511 0.84913 7.22712 1.02515C7.40314 1.20117 7.50202 1.4399 7.50202 1.68882C7.50202 1.93775 7.40314 2.17648 7.22712 2.3525L3.20194 6.3758H14.0626C14.3112 6.3758 14.5496 6.47456 14.7254 6.65036C14.9012 6.82615 15 7.06458 15 7.3132Z" fill="#A8A8A8"/>
                </svg>
                &nbsp;Back
              </Link>
            </div>
          </div>
        </header>



        <main className={main}>
          <article className={article}>
            <div>

              <div style={{ backgroundColor: "#FFFFFF", color: "#000000" }}>
                <div className={headingLogo}>
                  <BendableLogo style={{ height: '200px' }} />
                </div>
            

                <div className={twoLineHeader}>
                  <h2>Bendable <span className={bendableGold}>Maine</span></h2>
                  <h2>FAQs</h2>
                </div>

              </div>

              <div className={faqItem}>
                <div className={faqHeading}>What is Bendable?</div>
                <div className={faqBody}>
                  <p>
                  <a href="https://www.bendable.com/" target="_blank">Bendable</a> is a robust learning 
                  marketplace. It allows residents of all ages and
                  backgrounds to easily discover content that is just right for them on a wide
                  variety of subjects and then acquire new knowledge and skills through
                  online courses as well as local, in-person learning opportunities.
                  </p>
                  <p>
                  Bendable Maine will launch in summer 2022. The vast majority of the
                  learning made available through the system will be free to residents of the
                  state. (When there is a cost to the user, it will be clearly indicated.)
                  </p>
                  <p>
                  Best of all, the system is being shaped by Mainers for Mainers. Hundreds of
                  organizations and thousands of individuals across the state are participating
                  in workshops and other activities to help with design, content curation and
                  promotion.
                  </p>
                </div>
              </div>

              <div className={faqItem}>
                <div className={faqHeading}>Who is behind Bendable?</div>
                <div className={faqBody}>
                  <p>
                  Bendable is an offering of the <a href="https://www.maine.gov/msl/" target="_blank">Maine State Library</a> and <a href="https://bendablelabs.com" target="_blank">Bendable Labs</a>,
                  a technology, consulting and research firm that aims to improve economic stability, mobility and opportunity for people across the United States.
                  </p>
                </div>
              </div>

              <div className={faqItem}>
                <div className={faqHeading}>Is this another workforce development initiative?</div>
                <div className={faqBody}>
                  <p>
                  Having people learn new skills so that they can improve their job prospects
                  and advance in their careers is extremely important, and many of the
                  resources on Bendable Maine will be work-related. Indeed, we are
                  collaborating with major employers, labor leaders, workforce development
                  officials and others to ensure that we provide learning opportunities that are
                  truly relevant and in accord with <a href="https://www.maine.gov/decd/sites/maine.gov.decd/files/inline-files/DECD_120919_sm.pdf" target="_blank">Maine’s 10-year Economic Development
                  Strategy</a>.
                  </p>
                  <p>
                  But we also know from our research that people are eager to learn for
                  different reasons throughout their life—sometimes even throughout their
                  day. As a result, Bendable Maine will include resources on a wide variety of
                  topics, including cooking healthier meals, handling personal finances, fixing 
                  things around the house and understanding technology. Users will also be
                  able to tap Bendable to study science, literature, music, art and a host of
                  other subjects.
                  </p>
                  <p>
                  By offering this kind of breadth, we believe that Bendable Maine can achieve
                  its central mission: cultivating in people the habit of continuous learning, so
                  as to make them more resilient in the face of a fast-changing economy and
                  improve their overall sense of well-being.
                  </p>
                </div>
              </div>

              <div className={faqItem}>
                <div className={faqHeading}>I still don’t get it. Why do we need Bendable? When I want to learn
                  something, I just go to Google to find what I need or take a class
                  from Coursera or LinkedIn Learning or some other online education
                  platform.
                </div>
                <div className={faqBody}>
                  <p>
                  The Bendable lifelong learning system offers several distinct advantages
                  from what’s available elsewhere:
                  </p>
                  <ul>
                    <li>
                    It is an abundant marketplace, offering dozens of choices of content
                    providers. This gives everyone—no matter their level of formal
                    education—the best chance to find the learning that suits their needs
                    and interests, including the way they like to learn (through text or
                    video, bite-size lessons or longer sessions, online or in-person, etc.,
                    etc.).
                    </li>
                    <li>
                    While it is abundant, the system is also highly tailored. All of the
                    content is being curated by the Maine State Library, so you can be
                    sure that it is of the highest quality and meant for Maine. You can trust
                    it.
                    </li>
                    <li>
                    The system’s content catalog blends national online courses with local
                    learning opportunities from across the state.
                    </li>
                    <li>
                    Most of the content will be free to state residents, including through
                    pre-paid course seats that lead to industry-approved certifications.
                    You can’t get that by surfing the web.
                    </li>
                    <li>
                    While the system includes a great digital platform, it also is a hub of
                    place-based learning, featuring community-based, in-person study
                    sessions, learning meet-ups and other face-to-face activities.
                    </li>
                  </ul>
                  <p>
                  Learning is social, and people want to learn with each other and from
                  each other. Bendable Maine recognizes and leans into that.
                  </p>
                </div>
              </div>

              <div className={faqItem}>
                <div className={faqHeading}>OK, I’m starting to get it now. But how will all of that local learning
                content get put into the system and kept up to date? That sounds
                like a lot of work!
                </div>
                <div className={faqBody}>
                <p>
                  The Maine State Library and Bendable Labs are committed to building
                  out a statewide volunteer network that can keep local learning
                  opportunities up to date.
                  </p>
                  <p>
                  We’ll also be working with local libraries to make sure that a wide range of
                  community stakeholders—employers, nonprofits, schools and colleges,
                  government agencies and others—are contributing to the system and
                  drawing on its benefits.
                  </p>
                </div>
              </div>

              <div className={faqItem}>
                <div className={faqHeading}>What kind of technology is needed to access the Bendable Maine
                  digital platform?
                </div>
                <div className={faqBody}>
                  <p>
                  Bendable is a web application that can be accessed by any user with any
                  modern web browser on a desktop computer, laptop, tablet or smart
                  mobile device.
                  </p>
                  <p>
                  We are committed to making Bendable usable and accessible to the widest
                  possible audience, and we provide features that support accessibility. For
                  example, web pages on the Bendable platform are intended to be
                  compatible with screen readers and accessible to keyboard navigation.
                  </p>
                  <p>
                  We also comply with <a href="https://www.section508.gov/" target="_blank">federal accessibility standards</a>.
                  </p>
                  <p>
                  Beyond that, our goal is for Bendable to offer a delightful and inviting
                  experience for all, regardless of technology or ability. We leverage <a href="https://udlguidelines.cast.org/" target="_blank">Universal
                  Design for Learning (UDL) principles</a> in our design process and consistently
                  include learners with a range of abilities and different levels of digital
                  confidence in our co-design sessions and user testing with the community.
                  </p>
                </div>
              </div>

              <div className={faqItem}>
                <div className={faqHeading}>Is Bendable private and secure?</div>
                <div className={faqBody}>
                  <p>
                  For those who come to the Bendable platform and don’t sign up for an
                  account, no personal information is collected.
                  For those who do sign up for an account, Bendable collects limited
                  personally identifiable information (PII)—specifically, name and emailaddress. 
                  Account holders may also choose to provide Bendable with
                  additional profile information, such as ZIP code, library card number and
                  mobile phone number, to help maximize their experience on the platform.
                  </p>
                  <p>
                  Bendable will never share PII without consent, and we will never rent or sell
                  PII to anyone. We may share aggregate information about our user base
                  with our partners, and we may publish these aggregate usage statistics.
                  </p>
                  <p>
                  Bendable facilitates accessing learning content from third-party providers
                  that have been vetted and approved by Bendable and the Maine State
                  Library. Some of these third-party providers ask users for additional
                  information when registering for a course. Users should review each third-
                  party provider’s privacy policy to determine if they are comfortable with it.
                  </p>
                </div>
              </div>

              <div className={faqItem}>
                <div className={faqHeading}>Can young children use Bendable?</div>
                <div className={faqBody}>
                  <p>
                  The Bendable Platform is not intended to be used by young children alone.
                  </p>
                  <p>
                  You must be 14 years of age or older to create an account on the Bendable
                  platform. In accordance with the federal <a href="http://Children%C3%A2%E2%82%AC%E2%84%A2s%20Online%20Privacy%20Protection%20Act," target="_blank">Children’s Online Privacy
                  Protection Act</a>, Bendable will never knowingly solicit, nor accept, personally
                  identifiable information from Bendable users known to be under 14 years of
                  age.
                  </p>
                  <p>
                  That said, Bendable is meant for all ages. We encourage parents, guardians
                  and teachers to share learning content found on Bendable with younger
                  children, as appropriate.
                  </p>
                </div>
              </div>

              <div className={faqItem}>
                <div className={faqHeading}>Maine is a big, diverse place. How can you make this work for
                  everyone?
                </div>
                <div className={faqBody}>
                <p>
                  This is our central design challenge—and, by working with stakeholders
                  across the state, we are committed to cracking it: How can Bendable reflect
                  the cohesive identity that many Mainers proudly share while addressing the
                  specific needs of every distinct region and local community?
                  </p>
                  <p>
                  A big part of the way that we are solving for this is by working closely with
                  local public libraries across Maine; nobody knows their communities better.
                  We also have four Bendable Fellows on the ground across the state. Their
                  job is to listen to, and collaborate with, all stakeholders, ensuring that local
                  learning needs are being met and everyone has a seat at the table.
                  </p>
                </div>
              </div>

              <div className={faqItem}>
                <div className={faqHeading}>My community already has a network of great community-based
                  organizations. Is Bendable trying to elbow them out of the way?</div>
                <div className={faqBody}>
                  <p>
                  Just the opposite! We want community-based organizations, including local
                  libraries, to leverage Bendable so that they can better meet their own
                  missions and take care of those they serve.
                  </p>
                  <p>
                  In South Bend, Indiana, where Bendable was first launched in June 2020, this
                  model is playing out right now. Major local employers, like Beacon Health, are
                  using Bendable as a learning-and-development platform for their
                  employees. Goodwill case managers are sending their clients to Bendable
                  to learn new skills and help them make the transition from prison back into
                  the community. Public school teachers are directing their students to
                  Bendable as part of their lesson planning. A youth jobs initiative is weaving
                  Bendable-based resources on goal setting and career exploration into the
                  program. The St. Joseph County Public Library is integrating Bendable into a
                  good portion of its activities and events. And much, much more.
                  </p>
                  <p>
                  We have no doubt that, once it’s launched, Bendable Maine will similarly help
                  to enhance the great work that is already happening in communities
                  throughout the state.
                  </p>
                </div>
              </div>

              <div className={faqItem}>
                <div className={faqHeading}>OK, I’m sold on Bendable Maine! How do I get involved?</div>
                <div className={faqBody}>
                  <p>
                  If you represent an employer, nonprofit, government agency or other
                  stakeholder, or you’re an individual resident, just go to <a href="https://bendable.com" target="_blank">bendable.com</a>, click
                  on the tab for Maine and sign up at the top of the page.
                  </p>
                  <p>
                  If you’re a public librarian, please reach out to Janet McKenney at the Maine
                  State Library at <a mailto="janet.mckenney@maine.gov">janet.mckenney@maine.gov</a>.
                  </p>
                  <p>
                  Or simply contact a Bendable Fellow in your area: Northern Maine—Christina
                  Kane Gibson at <a mailto="christina@maine.bendable.com">christina@maine.bendable.com</a>; 
                  Central Maine—Kate Hunter at <a mailto="kate@maine.bendable.com">kate@maine.bendable.com</a>; 
                  Southern Maine and Downeast—Paul Salway at <a mailto="paul@maine.bendable.com">paul@maine.bendable.com</a> or Landis Hackett
                  at <a mailto="landis@maine.bendable.com">landis@maine.bendable.com</a>.
                  </p>
                </div>
              </div>



              

            </div>

          </article>
        </main>

        <footer id="colophon" className="Site-footer">
          <div className="Site-info">
            <div className="Site-logos">
              <a href="https://www.maine.gov/msl/" target="_blank" rel="noreferrer">
                <img width="146" height="146" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/Maine-State-Library-Logo.png" className="attachment-full size-full" alt="" loading="lazy" />
              </a>

              <div style={{ width: '60px', height: '30px' }} />
              
              <BendableLabsLogo
                width = {{ xs: '150px', sm: '200px', md: '200px', lg: '200px', xl: '250px' }}
              />

            </div>			
            
          </div>
          
        </footer>
      </div>

      <script src='https://bendable.s3.us-west-1.amazonaws.com/marketing/navigation.js?ver=1.0.0' id='bendable-navigation-js'></script>
      <script src='https://bendable.s3.us-west-1.amazonaws.com/marketing/flickity.js?ver=2.2' id='flickity-scripts-js'></script>
      <script src='https://bendable.s3.us-west-1.amazonaws.com/marketing/mobile-flickity-slider.js?ver=2.2' id='custom-flickity-scripts-js'></script>
      <script src='https://bendable.s3.us-west-1.amazonaws.com/marketing/wp-embed.min.js?ver=5.7.2' id='wp-embed-js'></script>
    </Layout>
  )
}

export default Faq;